<template>
    <div class="myselfMessageBlock" v-if="Number(message.attributes.user_id) === Number(USER.id)">
        <div class="myselfMessageTime">
            <span class="myselfMessageTime__time">{{ this.formatterTime(message.attributes.createdAt) }}</span>
        </div>

        <div class="myselfMessageContent">
            <div class="myselfMessageContent__text">{{ message.attributes.message }}</div>
          <chat-images
              :images="getMessageAttachments"
          />
        </div>
    </div>
    <div class="strangerMessageBlock" v-else>
        <div class="strangerUserWrapper">
            <div class="strangerAvatarWrapper">
                <img :src="getUser().attributes.logo" class="strangerAvatarWrapper__image">
            </div>
            <div :class="checkOnlineUser(message.attributes.user_id) ? 'strangerUser__online' : 'strangerUser__offline'"></div>
        </div>

        <div class="strangerMessage">
            <div :class="anotherUserMessageLogin">{{ getUser().attributes.login }}</div>
            <span class="strangerMessage__text">{{ message.attributes.message }}</span>
          <chat-images
              :images="getMessageAttachments"
          />
        </div>

        <div class="strangerTime">
            <span class="strangerTime__time">{{ this.formatterTime(message.attributes.createdAt) }}</span>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import moment from "moment";
import {OnlineUsers} from "@/otherJS/mixins/onlineUsers";
import {includeFilteringByType, getDataForRelation} from "@/otherJS/include-filtering";
import ChatImages from "@/components/tournaments/singleTournament/singleMatch/сhat/chat-images";

export default {
  name: "simpleMessage",
  components: {ChatImages},
  props: ['message', 'attachments'],
  mixins: [OnlineUsers],
  methods: {
      getUser() {
          let user = this.users.find(user => Number(user.id) === Number(this.message.attributes.user_id));

          if (user === undefined) {

              user = includeFilteringByType(this.chatIncluded, 'users').find(user => Number(user.id) === Number(this.message.attributes.user_id));
          }

          return user;
      },
      formatterTime(time) {
          return moment(time).format('LT');
      },
  },
  computed: {
      ...mapGetters([
          'USER',
          'COMPOSITIONS',
          'onlineUsers',
          'MATCH',
          'chatIncluded',
          'CHAT_ATTACHMENTS'
      ]),

      users() {
          let users = [];
          if (this.MATCH.data.attributes.participantable_first_type === 'teams') {
              this.COMPOSITIONS.forEach(function(usersCompositions) {
                  for (let user of usersCompositions.players) {
                      users.push(user);
                  }
              });
          } else {
              this.COMPOSITIONS.forEach(function(user) {
                  users.push(user.participant);
              });
          }
          return users;
      },

      anotherUserMessageLogin() {
          if (this.MATCH.data.attributes.participantable_first_type === 'teams') {
              let color = 0;
              this.users.forEach(function (user, key) {
                if (user.id === this.message.attributes.user_id) {
                      color = key;
                  }
              }, this);

              if (color === 0) {
                  return 'strangerMessage__author__first';
              } else {
                  return 'strangerMessage__author__second';
              }
          } else {
              let color;
              this.users.forEach(function (user) {
                  if (user.id === this.MATCH.data.attributes.participantable_first_id) {
                      color = 0;
                  } else if (user.id === this.MATCH.data.attributes.participantable_second_id) {
                      color = 1;
                  } else {
                      color = 2
                  }
              }, this);

              if (color === 0) {
                  return 'strangerMessage__author__first';
              } else if (color === 1) {
                  return 'strangerMessage__author__second';
              } else {
                  return 'strangerMessage__author__judge';
              }
          }
      },

      chatAttachments() {
        return this.CHAT_ATTACHMENTS.concat(this.attachments)
      },

      messageAttachments() {
        let attachments = []

        for (let i = 0; i < this.message.relationships.attachments.data.length; i++) {
          let relatedAttachment = this.message.relationships.attachments.data[i]

          if (relatedAttachment.attributes === undefined) {
            relatedAttachment = this.chatAttachments.find(attachment => Number(attachment.id) === Number(relatedAttachment.id))
          }

          attachments.push(relatedAttachment)

        }

        return attachments;
      },

      getMessageAttachments() {
        return this.messageAttachments
      }
  },
}
</script>

<style lang="scss" scoped>

.dialogsAndSystemsContent {
    padding: 0 16px;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    .dialogsAndSystemsContentTimeBlock {
        padding-bottom: 20px;
        padding-top: 20px;

        &__text {
            font-family: 'SF UI Text', serif;
            font-weight: normal;
            line-height: 17px;
            color: white;
            opacity: .6;
            font-size: 14px;
        }
    }

    .noticeUserInvited {
        margin-top: 4px;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;

        &__participant {
            font-family: 'SF UI Text', serif;
            font-weight: normal;
            line-height: 14.4px;
            font-size: 12px;

            &_first {
                color: #0A84FF;
            }

            &_second {
                color: #FF453A
            }
        }

        &__invite {
            margin-left: 5px;
            font-family: 'SF UI Text', serif;
            font-weight: normal;
            line-height: 14.4px;
            font-size: 12px;
            color: white;
            opacity: .6;
        }

        &__time {
            margin-left: 16px;
            font-family: 'SF UI Text', serif;
            font-weight: normal;
            line-height: 14.4px;
            font-size: 12px;
            color: white;
            opacity: .6;
        }
    }

    .strangerMessageBlock {
        margin-top: 8px;
        width: 100%;
        align-self: flex-start;
        flex-direction: row;
        display: flex;
        align-items: flex-end;

        .strangerUserWrapper {
            width: 40px;
            height: 40px;

            .strangerAvatarWrapper {
                width: 40px;
                height: 40px;
                border-radius: 100%;
                overflow: hidden;

                &__image {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }

            .strangerUser {
                &__online {
                    position: relative;
                    margin-left: 30px;
                    top: -26%;
                    height: 6px;
                    width: 6px;
                    border-radius: 50%;
                    background: var(--tournament-color);
                }

                &__offline {
                    position: relative;
                    margin-left: 30px;
                    top: -26%;
                    height: 6px;
                    width: 6px;
                    border-radius: 50%;
                    background: #757575;
                }
            }
        }

        .strangerMessage {
            max-width: 75%;
            width: fit-content;
            padding: 11.5px 16px;
            margin-left: 8px;
            background-color: #3A3A3A;
            border-radius: 4px;
            word-wrap: break-word;

            &__text {
                color: white;
                font-family: 'SF UI Text', serif;
                font-size: 14px;
                line-height: 16.8px;
                font-weight: normal;
            }

            &__author {
                font-family: "SF UI Text", serif;
                font-size: 16px;
                line-height: 16.8px;
                font-weight: normal;

                &__first {
                    color: #FF453A;
                }

                &__second {
                    color: #0A84FF;
                }

                &__judge {
                    color: #E2FF31;
                }
            }
        }

        .strangerTime {
            &__time {
                color: white;
                opacity: .6;
                font-family: 'SF UI Text', serif;
                font-size: 12px;
                line-height: 14.4px;
                font-weight: normal;
            }
        }
    }

    .myselfMessageBlock {
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
        align-items: flex-end;
        width: 100%;
        margin-top: 8px;

        .myselfMessageTime {
            &__time {
                color: white;
                opacity: .6;
                font-family: 'SF UI Text', serif;
                font-size: 12px;
                line-height: 14.4px;
                font-weight: normal;
            }
        }

        .myselfMessageContent {
            max-width: 75%;
            width: fit-content;
            padding: 11.5px 16px;
            margin-left: 8px;
            background-color: #757575;
            border-radius: 4px;
            word-wrap: break-word;

            &__text {
                color: white;
                font-family: 'SF UI Text', serif;
                font-size: 14px;
                line-height: 16.8px;
                font-weight: normal;
            }
        }
    }

    &:hover {
        overflow-y: scroll;
        padding: 0 8px 0 16px;
    }

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #515151;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #242424;
        border-radius: 6px;
        border: 3px solid #515151;
    }
}


</style>
